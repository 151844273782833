<template>
  <div class="PartnerHeader">
        <div class="logos">
        <div class="partner-logo-cont">
          <a :href="website" target="_blank">
            <img 
            :src="logo" 
            class="partner-logo"
            >
          </a>
        </div>
        <div class="sawbo-logo-cont">
          <a href="https://sawbo-animations.org" target="_blank">
            <img 
              src="https://sawbo-animations.org/moalfac/img/SAWBO_Logo_500px.jpg"
              class="sawbo-logo">
          </a>
        </div>
      </div>
      <div class="rapid-wordmark">SAWBO {{abbr}}</div>
      <nav class="rapid-bar-menu">
          <router-link class="rapid-bar-menu-link" to="/">Videos</router-link>
          <router-link class="rapid-bar-menu-link" to="/about">About</router-link>
          <a :href="website" target="_blank" class="rapid-bar-menu-link">{{abbr}} Website</a>
          <a href="https://sawbo-animations.org" target="_blank" class="rapid-bar-menu-link">SAWBO Website</a>
      </nav>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: "PartnerHeader",
  props: {},
  data() {
    return {};
  },
  computed: {
      ...mapGetters([
        'organization',
      ]),
      name() {
        return this.organization.name;
      },
      abbr() {
        return this.organization.abbreviation;
      },
      website() {
        return this.organization.website;
      },
      logo() {
        return this.organization.logo;
      }
  },
  methods: {
  }
};
</script>

<style scoped>

.PartnerHeader {
  width: 100%;
  padding: 2% 0;
}

.logos {
  width: 100%;
  padding: 0 5%;
  height: 10em;
  border-bottom: 2px solid #fafafa;
}

.partner-logo-cont {
  width: 45%;
  height: 100%;
  float: left;
}

.partner-logo {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.sawbo-logo-cont {
  width: 45%;
  height: 100%;
  float: right;
  padding-top: 4%;
}

.sawbo-logo {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  vertical-align: middle;
}

.rapid-wordmark {
  width: 100%;
  padding: 0 5%;
  color: #3B5783;
  font-size: 3em;
  font-weight: bold;
}

.rapid-bar-menu {
  padding: .5% 5%;
  background-color: #3B5783;
  color: #ffffff;
  text-align: right;
}

.rapid-bar-menu-link {
  color: #ffffff;
  text-decoration: none;
  padding-left: 15px;
}

.rapid-bar-menu-link:hover {
  text-decoration: underline;
}

</style>
