<template>
    <div class="PartnerFooter">
        <div class="contact-text">
          Contact us at <a href="mailto:contact@sawbo-animations.org">contact@sawbo-animations.org</a>
          <br><br>
          Scientific Animations Without Borders &copy; {{ currentYear }} | <a href="https://sawbo-animations.org/disclaimer/" target="_blank">Disclaimer</a>
        </div>
        <div class="footer-logos">
          <div class="footer-logo-cont">
            <a :href="organization.website" target="_blank">
              <img 
                :src="organization.logo"
                class="footer-logo partner-logo"
              >
            </a>
          </div>
          <div class="footer-logo-cont">
            <a :href="organization.secondaryWebsite" target="_blank">
              <img 
                :src="organization.secondaryLogo"
                class="footer-logo partner-logo secondary-partner-logo"
              >
            </a>
          </div>
          <!--<div class="footer-logo-cont">
            <a href="https://www.purdue.edu/" target="_blank">
              <img 
                src="https://sawbo-animations.org/moalfac/img/Purdue-University-Logo.png"
                class="footer-logo purdue-logo">
            </a>
          </div>-->
          <div class="footer-logo-cont">
            <a href="https://sawbo-animations.org" target="_blank">
              <img 
                src="https://sawbo-animations.org/moalfac/img/SAWBO_Logo_500px.jpg"
                class="footer-logo sawbo-logo">
            </a>
          </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PartnerFooter',
  props: {
  },
  data () {
      return {
      }
  },
  computed: {
    ...mapGetters([
      'organization',
    ]),
    currentYear() {
      let d = new Date();
      return d.getFullYear();
    }
  }
}
</script>

<style scoped>
.PartnerFooter {
  width: 100%;
  padding: 2% 5%;
  border-top: 2px solid #fafafa;
}
.contact-text {
  width: 100%;
  font-size: .8em;
  text-align: center;
  padding-bottom: 2%;
  border-bottom: 2px solid #fafafa;
}
.contact-text a {
    color: #3B5783;
}
.footer-logos {
  width: 100%;
  padding-top: 1%;
}
.footer-logo-cont {
  width: 33%;
  display: inline-block;
  padding: 0;
}
.footer-logo {
  width: 100%;
}
.partner-logo {
  width: 70% !important;
  float: left;
}
.secondary-partner-logo {
  width: 80% !important;
}
.purdue-logo {
  width: 90% !important;
  margin-left: 5%;
  float: left;
}
.sawbo-logo {
  width: 80% !important;
  float: right;
  margin-right: 2%;
  padding-bottom: 4%;
}
</style>
