<template>
  <div class="page">
    <section class="about">
      <html-loader/>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HtmlLoader from "../components/utils/HtmlLoader";

export default {
  name: "About",
  metaInfo() {
    return {
      title: "About SAWBO and "+this.abbr,
      meta: [
        {
          name: "description",
          content: "Funded through a grant from Feed the Future, the U.S. Government's global hunger and food security initiative, SAWBO RAPID serves as an educational intervention program to disseminate crucial information related to COVID-19's secondary economic impacts, including disruption to trade, supply chains and markets."
        },
        { property: "og:title", content: "About SAWBO and "+this.abbr },
        { property: "og:image", content: "https://sawbo-animations.org/images/social_media/SAWBO_FB_Share.png" },
        { property: "og:url", content: window.location.origin + window.location.pathname },
        { property: "og:type", content: "website" }
      ],
    };
  },
  components: {
    HtmlLoader
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
        'organization',
    ]),
    name() {
      return this.organization.name;
    },
    abbr() {
      return this.organization.abbreviation;
    },
    displayName() {
      if (this.abbr) {
        return this.name + " (" + this.abbr + ")";
      } else {
        return this.name;
      }
    }
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.about {
  padding-bottom: 2%;
}
.blue-hr {
  color: #3b5783;
  margin: 4% 0;
}
</style>
