<template>
    <div>
    <h2>About the Republic of Kenya Ministry of Agriculture and Livestock Development and Scientific Animations Without Borders</h2>
      <p>
        This website is a collaboration between the Agricultural Information Resource Centre (AIRC) under the Republic of Kenya Ministry of Agriculture and Livestock Development (MoA&LD) and Scientific Animations Without Borders (SAWBO).
      </p>
      <h3>Ministry of Agriculture and Livestock Development and the Agricultural Information Resource Centre</h3>
      <p>
        Agricultural Information Resource Centre is a Directorate in the State Department for crop development and agricultural research in the Ministry of Agriculture and Livestock Development. The Centre started in 1966 to disseminate agricultural information to farmers, extension workers and other stakeholders.
      </p>
      <p>
        AIRC is dedicated to serving its clients in the agriculture sector with specialized agricultural information through innovation and modern technologies. Our vision is to be the choice centre of agricultural information, knowledge and skills in Africa and beyond.
      </p>
      <p>
        Our mission is to collect, process and share quality agricultural information and knowledge resources to the farming community and other stakeholders using integrated platforms.
      </p>
      <p>
        The mandate of AIRC is to collect, process and share Agricultural Information to farmers, CBOs, NGOs and Private sector. AIRC in partnership with various stakeholders in the Sector continue to produce agricultural mass media information, education and communication (IEC) materials that are disseminated to clients through integrated media platforms.
      </p>
      <p>
        <b>CORE VALUES</b>
      </p>
      <p>
        <ul>
          <li>We show Commitment to our work and clients</li>
          <li>We embrace Team work</li>
          <li>We advocate Creativity in our work</li>
          <li>We exercise Professionalism in our work</li>
          <li>We will Endeavour to promote and embrace Partnership and collaboration with our stakeholders.</li>
          <li>Our products and services are of high Quality and standards.</li>
          <li>We uphold Integrity and Respect</li>
          <li>We practice Gender equity</li>
        </ul>
      </p>
      <p>
        AIRC implements this mandate through the following specialized units:
      </p>
      <p>
        <ul>
          <li>Mass Media Services Unit (consists of Radio, Video and Publication sub-units)</li>
          <li>Agricultural Documentation Services Unit (ADoc)</li>
          <li>Agricultural Library Information Services Unit (ALIS)</li>
          <li>Training and Conference Unit</li>
          <li>Business Development and Marketing Unit</li>
          <li>Monitoring and Evaluation Unit</li>
        </ul>
      </p>
      <p>To learn more about AIRC, please click <a href="https://airc.go.ke/" target="_blank">here</a>.</p>
      <p>To learn more about MoA&LD, please click <a href="https://kilimo.go.ke/about-us/" target="_blank">here</a>.</p>
      <h3>Scientific Animations Without Borders</h3>
      <p>
        SAWBO is a Purdue University based program. SAWBO transforms extension information on relevant topics such as agriculture, disease and women's empowerment, into 2D, 2.5D and 3D animations, which are then voice overlaid into a diversity of languages from around the world. All SAWBO animations are made freely available to anyone wishing to use them for educational purposes. Animations can be downloaded from a diversity of SAWBO channels and used on computers, tablets, cell phones, TVs, and overhead projection systems.
      </p>
      <p>
        To learn more about SAWBO, please click <a href="https://sawbo-animations.org/home/" target="_blank">here</a>.
      </p>
    </div>
</template>

<script>

export default {
  name: 'HtmlLoader',
  props: {

  },
  data () {
      return {
        
      }
  }

}
</script>

<style scoped>
h2 {
  padding-bottom: 1.5%;
}
p {
  padding-bottom: 2.5%;
}
ul {
  margin-left: 15px;
}
</style>
