import sysOutDev from '../../data/sysOut';

let videos;
let topics;

if (process.env.NODE_ENV === 'development') {
    videos = sysOutDev.videos;
    topics = sysOutDev.topics;
} else {
    videos = window.sysOut.videos;
    topics = window.sysOut.topics;
}

const state = {
    videos: videos,
    topics: topics,
}

const mutations = {
}

const actions = {
}

const getters = {
    videos: state => {
        return state.videos;
    },
    videosList: state => {
        let v = [];
        let t = Object.keys(state.topics);
        t.forEach(topic => {
            if (state.topics[topic].videos && state.topics[topic].videos.length > 0) {
                v.push(state.topics[topic].videos[0]);
            }
        });
        return v;
    },
    topics: state => {
        return state.topics;
    },
    homeVideos: (state, getters) => {
        let arr = getters.videosList;
        let arrLen = arr.length;
        if (arrLen > 2) {
            return arr.slice(arrLen-2);
        } else {
            return arr;
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}